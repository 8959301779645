import { trigger, transition, style, animate } from '@angular/animations';

export const openCloseMenu = trigger('openClose', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'translateY(10%)',
            boxShadow: 'none',
        }),
        animate(
            150,
            style({
                opacity: 1,
                transform: 'translateY(0)',
                boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.3)',
            })
        ),
    ]),
    transition(':leave', [
        animate(
            150,
            style({
                opacity: 0,
                transform: 'translateY(10%)',
                boxShadow: 'none',
            })
        ),
    ]),
]);

export const growHeight = (h: number) =>
    trigger('growHeight', [
        transition(':enter', [
            style({
                height: 0,
                'min-height': 0,
            }),
            animate(
                150,
                style({
                    height: h,
                    'min-height': h,
                })
            ),
        ]),
    ]);
